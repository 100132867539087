@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");

/* 
* Theme Styles: Base 
*/

/* For Webkit browsers (Chrome, Safari, and newer versions of Edge) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track background */
}

::-webkit-scrollbar-thumb {
  background-color: #004a97; /* Scrollbar thumb color */
  border-radius: 10px; /* Roundness of the scrollbar thumb */
  border: 3px solid #f1f1f1; /* Creates padding around the scrollbar thumb */
}

/* For Firefox */
* {
  scrollbar-color: #004a97 #f1f1f1;
  scrollbar-width: thin;
  scrollbar-color: #0a5599;
}

::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #0a5599;
  border-radius: 10px;
  border: 2px solid transparen;
}

body,
.editor-styles-wrapper {
  background-color: #f5f5f5;
  font-family: "Open Sans", sans-serif;
  font-family: var(--body-font);
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Open Sans", sans-serif;
  font-family: var(--body-font);
}

a[href]{
  text-decoration-line: underline;
}

a[href]:hover{
  opacity: 0.8;
}

@media (min-width: 1068px){
  .hidden-desktop{
    display: none;
  }
}

.xs-full-width{
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
}

@media (min-width: 1068px){
  .xs-full-width{
    left: auto;
    right: auto;
    margin-left: 0px;
    margin-right: 0px;
    width: auto;
  }
}

.full-width{
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
}

p{
  font-size: 18px;
  --tw-text-opacity: 1;
  color: rgb(63 71 80 / var(--tw-text-opacity, 1));
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.swiper-wrapper {
  transition-timing-function: linear !important;
}

.swiper-slide {
  transition: opacity 1.5s linear !important;
}

.rmsc {
  --rmsc-main: #004a97;
  --rmsc-hover: #f1f3f5;
  --rmsc-selected: #e2e6ea;
  --rmsc-border: transparent;
  --rmsc-gray: #aaa;
  --rmsc-bg: #fff;
  --rmsc-p: 10px;
  --rmsc-radius: 5px;
  --rmsc-h: 38px;

  min-width: 140px;
  border-radius: 20px;
}

.rmsc .dropdown-content {
  width: -moz-max-content !important;
  width: max-content !important;
}

.rmsc .item-renderer {
  align-items: center !important;
}

.rmsc .item-renderer input {
  width: 16px;
  height: 16px;
  background-color: #fff;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #212121;
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
  display: inline-block;
  box-sizing: border-box;
  margin: 0;
  padding: 8px;
}

.rmsc .select-item {
  font-size: 16px;
}

.rmsc .item-renderer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.rmsc .item-renderer span {
  width: -moz-fit-content;
  width: fit-content;
}

.rmsc .item-renderer input:checked {
  background-color: #212121;
  border-color: #212121;
}

.rmsc .options {
  padding: 0;
}

.rmsc .gray {
  font-weight: bold;
  color: #2d2d32 !important;
}

.rmsc .dropdown-container:focus-within {
  box-shadow: none !important;
}

.rmsc .dropdown-heading-value {
  margin-right: 15px;
  font-weight: 600;
}

.rmsc .dropdown-heading {
  border-radius: 10px !important;
}

.rmsc .dropdown-container {
  border: none !important;
  border-radius: 10px !important;
}

select {
  background-color: white;
  border: thin solid #21586c;
  border-radius: 4px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.5em 3.5em 0.5em 1em;
  width: 100%;

  margin: 0;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: linear-gradient(45deg, transparent 50%, #0b4e8b 50%),
    linear-gradient(135deg, #0b4e8b 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);

  background-position: calc(100% - 19px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), 100% 0;
  background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
  background-repeat: no-repeat;
}

select:focus {
  outline: 0;
}

@media (max-width: 560px) {
  select {
    background-image: linear-gradient(45deg, #0b4e8b 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #0b4e8b 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: none;
    outline: 0;
  }

  select:focus {
    background-image: linear-gradient(45deg, #0b4e8b 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, #0b4e8b 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size: 5px 5px, 5px 5px, 1px 1.5em;
    background-repeat: no-repeat;
    border-color: none;
    outline: 0;
  }
}

/* 
* Theme Styles: Headings 
*/

html h1{
  font-size: 24px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h1{
    font-size: 32px;
  }
}

html h2{
  font-size: 24px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h2{
    font-size: 24px;
  }
}

html h3{
  font-size: 16px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h3{
    font-size: 20px;
  }
}

html h4{
  font-size: 14px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h4{
    font-size: 16px;
  }
}

html h5{
  font-size: 14px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h5{
    font-size: 14px;
  }
}

html h6{
  font-size: 14px;
  font-weight: 600;
}

@media (min-width: 1068px){
  html h6{
    font-size: 14px;
  }
}

.content-wrapper{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

@media (min-width: 740px){
  .content-wrapper{
    max-width: 740px;
  }
}

@media (min-width: 1068px){
  .content-wrapper{
    max-width: 1068px;
  }
}

@media (min-width: 1224px){
  .content-wrapper{
    max-width: 1224px;
  }
}

@media (min-width: 1504px){
  .content-wrapper{
    max-width: 1504px;
  }
}

.content-wrapper{
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2.5rem;
  min-height: 100vh;
}

@media (min-width: 1068px){
  .content-wrapper{
    margin-top: 2rem;
    margin-bottom: 6rem;
  }
}

.content-wrapper { /* md:mt-14 md:mb-24;*/
}

.page-title{
  margin-bottom: 1.5rem;
  font-size: 24px;
  font-weight: 600;
  line-height: 48px;
}

@media (min-width: 1068px){
  .page-title{
    font-size: 32px;
  }
}

.edit-post-visual-editor__post-title-wrapper{
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

/* 
* Gutenberg Blocks (Only add here if its necessary)
*/

.faq-group .has-accordion-dots:not(:last-child).closed:before,
.faq-group > .wp-block div:not(:last-child) .has-accordion-dots.closed:before{
  position: absolute;
  top: 0px;
  left: 0px;
  margin-left: 0.75rem;
  margin-top: 5rem;
  height: 100%;
  width: 0.625rem;
  background-image: url("data:image/svg+xml,%3Csvg width='8' height='32' viewBox='0 0 8 32' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='4' cy='4' r='4' fill='%230A5599'/%3E%3C/svg%3E%0A");
  background-size: 8px 32px;
  background-repeat: no-repeat;
  --tw-content: '';
  content: var(--tw-content);
}

/* Hide scrollbars */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* 
* Gutenberg Editor - Reset
*/

.block-editor-link-control {
  min-width: auto;
}

.components-base-control {
  margin-left: 0;
}

.editor-styles-wrapper a{
  font-size: inherit;
  color: inherit;
  text-decoration-line: none;
}

.block-editor-url-input input[type="text"] {
  width: -moz-fit-content;
  width: fit-content;
}

.editor-styles-wrapper input,
.editor-styles-wrapper button,
.editor-styles-wrapper textarea,
.editor-styles-wrapper select{
  border-width: 0px;
  font-family: inherit;
  line-height: 1.5;
}

.editor-styles-wrapper .wp-block-post-content{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

@media (min-width: 740px){
  .editor-styles-wrapper .wp-block-post-content{
    max-width: 740px;
  }
}

@media (min-width: 1068px){
  .editor-styles-wrapper .wp-block-post-content{
    max-width: 1068px;
  }
}

@media (min-width: 1224px){
  .editor-styles-wrapper .wp-block-post-content{
    max-width: 1224px;
  }
}

@media (min-width: 1504px){
  .editor-styles-wrapper .wp-block-post-content{
    max-width: 1504px;
  }
}

.editor-styles-wrapper .wp-block-post-content{
  margin-left: auto;
  margin-right: auto;
}

.editor-styles-wrapper{
  line-height: 150%;
}

html :where(.editor-styles-wrapper) p{
  margin: 0px;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 40px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  min-width: 400px;
}

.table-container th,
.table-container td {
  border: 1px solid #ffe7e7;
  padding: 10px;
  text-align: center;
}

/* Responsive styles */
@media (max-width: 600px) {
  table.table-container {
    width: 100%;
    font-size: 14px;
  }

  .table-container th,
  .table-container td {
    padding: 8px;
  }
}

/* 
* General Wordpress Styles 
*/

.wp-block-post-title {
  margin-bottom: 30px;
}

body .editor-styles-wrapper :where(body .is-layout-flex){
  gap: 0px;
}

body .editor-styles-wrapper .wp-block-post-content{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 24px;
  padding-left: 24px;
}

@media (min-width: 740px){
  body .editor-styles-wrapper .wp-block-post-content{
    max-width: 740px;
  }
}

@media (min-width: 1068px){
  body .editor-styles-wrapper .wp-block-post-content{
    max-width: 1068px;
  }
}

@media (min-width: 1224px){
  body .editor-styles-wrapper .wp-block-post-content{
    max-width: 1224px;
  }
}

@media (min-width: 1504px){
  body .editor-styles-wrapper .wp-block-post-content{
    max-width: 1504px;
  }
}

body .editor-styles-wrapper .wp-block-post-content{
  margin-left: auto;
  margin-right: auto;
}

.wp-block-video video{
  width: 100%;
}

@media only screen and (min-width: 1100px) {
  .hide-on-desktop {
    display: none !important;
  }
}
@media all and (min-width: 500px) and (max-width: 1100px) {
  .hide-on-tablet {
    display: none !important;
  }
}
@media screen and (max-width: 500px) {
  .hide-on-mobile {
    display: none !important;
  }
}

/* Menu */
#nav-menu ul.submenu.open{
  visibility: visible !important;
  max-height: 100vh !important;
}

@media (min-width: 1224px) {
  #nav-menu li > ul{
    --tw-translate-x: 100%;
    --tw-scale-x: 0;
    --tw-scale-y: 0;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #nav-menu li:hover svg{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  #nav-menu li:hover > ul.submenu{
    visibility: visible;
    height: 100%;
    max-height: 300px;
    width: -moz-max-content;
    width: max-content;
    min-width: 100%;
    --tw-translate-x: 0%;
    --tw-scale-x: 1;
    --tw-scale-y: 1;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    --tw-bg-opacity: 1;
    background-color: rgb(11 78 139 / var(--tw-bg-opacity, 1));
    --tw-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.05);
    --tw-shadow-colored: 0px 8px 12px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  @media (min-width: 1068px){
    #nav-menu li:hover > ul.submenu{
      height: auto;
    }
  }

  #nav-menu li:hover > ul.submenu > li{
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media (max-width: 1224px) {
  #nav-menu li a.has-submenu.active svg{
    --tw-rotate: 180deg;
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
}

/* User states */
.user-state-mobile{
  margin: 0px;
  display: none;
}

.user-state-mobile a{
  background-color: rgb(38 50 56 / 0.3);
}

body.is-logged-out .user-state-mobile.is-logged-out{
  display: block !important;
}

@media (min-width: 1068px){
  body.is-logged-out .user-state-mobile.is-logged-out{
    display: none !important;
  }
}

body.is-logged-in .user-state-mobile.is-logged-in{
  display: block !important;
}

@media (min-width: 1068px){
  body.is-logged-in .user-state-mobile.is-logged-in{
    display: none !important;
  }
}

/* Links & Hover effects */
.link-hover{
  position: relative;
  display: block;
  width: -moz-fit-content;
  width: fit-content;
}
.link-hover::after{
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  transform-origin: center;
  --tw-scale-x: 0;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  background-color: currentColor;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  --tw-content: '';
  content: var(--tw-content);
}
.link-hover:hover::after{
  content: var(--tw-content);
  --tw-scale-x: 1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/* General Styles for both UL and OL */
ul,
ol {
  margin: 1em 0;
  padding: 0 1.5em;
  line-height: 1.6;
  font-family: Arial, sans-serif;
  font-size: 16px;
}

/* Unordered List Styles */
ul {
  list-style-type: disc; /* You can change to circle or square */
}

ul li {
  margin-bottom: 0.5em;
}

/* Ordered List Styles */
ol {
  list-style-type: decimal; /* Options: decimal, lower-roman, upper-alpha, etc. */
}

ol li {
  margin-bottom: 0.5em;
}

/* Extra */
.tovek-auction-faq {
  letter-spacing: 0.2px;
}

.tovek-auction-faq .clean * {
  font-weight: 400;
  margin: 0;
  color: #3f4750;
}

.tovek-auction-faq > p {
  margin-top: 0 !important;
}

.tovek-auction-faq strong {
  font-size: 18px;
  font-weight: bold;
}

.tovek-auction-faq {
  font-weight: 400;
}

.tovek-auction-faq p,
.tovek-auction-faq h4,
.tovek-auction-faq strong,
.tovek-auction-faq h3 {
  margin: 10px 0;
}

.tovek-auction-faq p,
.tovek-auction-faq h4,
.tovek-auction-faq span,
.tovek-auction-faq h3 {
  font-weight: 400;
  font-size: 18px;
  color: #3f4750;
}

.tovek-auction-faq iframe{
  margin-bottom: 1rem;
  height: 300px;
  width: 100%;
}

@media (min-width: 1068px){
  .tovek-auction-faq iframe{
    width: 500px;
  }
}

.read-more-box,
.read-more-box p{
  font-size: 16px;
}

.read-more-box.is-expanded p{
  display: block;
}

.allow-html p{
  font-size: 16px;
}

.breadcrumbs li{
  margin: 0px;
}

/* WP Overrides since Gutenberg is not working */
@media (max-width: 768px) {
  .wp-block-group{
    padding: 1.75rem !important;
  }
}

